@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@button' as *;
@use '@queries' as *;

.card-inventory {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include fixedSize(100%, 114px);
  align-items: center;
  gap: 12px;

  &.disabled {
    > * {
      opacity: 1;
    }

    > *:not([id='bottom-slot']) {
      opacity: 0.6;
    }

    pointer-events: none;
  }

  @include min-428-break {
    justify-content: space-between;
    @include fixedSize(100%, 134px);
  }

  .top-slot {
    display: flex;
    align-items: center;
    width: inherit;
    justify-content: space-between;
    gap: 6px;
    min-height: 64px;

    @include min-428-break {
      min-height: 79px;
    }

    &--right {
      display: flex;
      gap: 12px;
      align-items: center;

      @include min-428-break {
        gap: 14px;
      }
    }
  }

  .bottom-slot {
    display: flex;
    align-items: center;
    width: inherit;
    gap: 8px;
  }
}

.item-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  justify-self: left;

  .item {
    z-index: 1;
    @include singleFixedSize(48px);

    @include min-428-break {
      @include singleFixedSize(60px);
    }
  }

  .item-rarity {
    position: absolute;

    @include singleFixedSize(64px);

    @include min-428-break {
      @include singleFixedSize(80px);
    }
  }
}

.item-info-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      color: var(--cst-cards-item-primary-title-default);
      @include typography-xs;
      @include weight-medium;

      @include min-428-break {
        @include typography-s;
      }
    }

    .subtitle {
      color: var(--cst-cards-item-primary-type-text-default);
      @include typography-xs;
      @include weight-medium;

      @include min-428-break {
        @include typography-s;
      }
    }

    .price {
      display: flex;
      gap: 4px;
      align-items: center;

      &--current {
        @include typography-base;
        @include weight-semi-bold;
        color: var(--cst-cards-item-page-newprice);

        @include min-428-break {
          @include typography-m;
        }
      }

      &--old {
        @include typography-s;
        @include weight-semi-bold;
        color: var(--cst-cards-item-page-oldprice);

        @include min-428-break {
          @include typography-s;
        }
      }
    }
  }
}

.item-properties-container {
  display: flex;
  align-items: center;
  width: max-content;
  background: var(--cst-cards-shoping-item-background-tags);
  border-radius: var(--cornerradius8);
  padding: 8px;
  gap: 6px;

  @include fixedSize(max-content, 30px);

  @include min-428-break {
    gap: 8px;
    @include fixedSize(max-content, 34px);
  }

  .category,
  .rarity {
    @include singleFixedSize(14px);

    @include min-428-break {
      @include singleFixedSize(18px);
    }
  }
}

.item-paddings {
  padding: 5.3px;

  @include min-428-break {
    padding: 6.67px;
  }
}

.spending-progress {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;

  @include min-428-break {
    gap: 8px;
  }

  @include min-744-break {
    gap: 14px;
  }

  .price {
    display: flex;
    align-items: center;
    gap: 2px;

    .current {
      @include typography-base;
      @include weight-semi-bold;
      color: var(--cst-cards-shoping-item-new-price);
      @include min-428-break {
        @include typography-m;
      }
    }

    .total {
      @include typography-xs;
      @include weight-semi-bold;
      color: var(--cst-cards-shoping-item-old-price);

      @include min-428-break {
        @include typography-s;
      }
    }
  }

  .content {
    @include typography-xs;
    @include weight-medium;
    @include fixedSize(190px, 30px);

    color: var(--cst-cards-shoping-item-title);

    @include min-428-break {
      @include typography-s;

      @include fixedSize(274px, 36px);
    }

    @include min-744-break {
      @include fixedSize(562px, 18px);
    }
  }
}
