@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@button' as *;
@use '@queries' as *;

.card-item-live {
  display: flex;
  @include fixedSize(100%, 64px);
  align-items: center;
  gap: 6px;

  @include min-428-break {
    justify-content: space-between;
    @include fixedSize(100%, 80px);
  }
}

.item-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  justify-self: left;

  .item {
    z-index: 2;
    @include singleFixedSize(48px);

    @include min-428-break {
      @include singleFixedSize(60px);
    }
  }

  .item-rarity {
    position: absolute;

    @include singleFixedSize(64px);

    @include min-428-break {
      @include singleFixedSize(80px);
    }
  }
}

.item-info-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      @include typography-s;
      @include weight-semi-bold;
      color: var(--cst-cards-shoping-item-subtitle);

      @include min-428-break {
        @include typography-base;
      }
    }

    .age {
      color: var(--cst-cards-item-page-oldprice);
      text-transform: capitalize;
      @include typography-xs;
      @include weight-medium;

      @include min-428-break {
        @include typography-s;
      }
    }
  }
}

.item-properties-container {
  display: flex;
  align-items: center;
  width: max-content;
  background: var(--cst-cards-shoping-item-background-tags);
  border-radius: var(--cornerradius8);
  padding: 8px;
  gap: 6px;

  @include fixedSize(max-content, 30px);

  @include min-428-break {
    gap: 8px;
    @include fixedSize(max-content, 34px);
  }

  .category,
  .rarity {
    @include singleFixedSize(14px);

    @include min-428-break {
      @include singleFixedSize(18px);
    }
  }
}

.item-paddings {
  padding: 5.3px;

  @include min-428-break {
    padding: 6.67px;
  }
}
