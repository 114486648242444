@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-item-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: relative;
  overflow: hidden;
  padding: 8px;
  z-index: 10;
  border-radius: var(--cornerradius10);
  outline: 2px solid var(--cst-cards-item-block-outline);
  outline-offset: -2px;
  @include fixedSize(100%, 100%);

  aspect-ratio: 292 / 197; //@include fixedSize(292px, 197px);
  @include min-428-break {
    aspect-ratio: 400 / 268; //@include fixedSize(400px, 268px);
    padding: 10px;
    border-radius: var(--cornerradius14);
  }

  @include min-744-break {
    aspect-ratio: 340 / 230; //@include fixedSize(340px, 230px);
    max-width: 340px;
    max-height: 230px;
    padding: 10px;
  }

  .item-rarity {
    position: absolute;
    z-index: -1;
    padding: 0px;
    overflow: hidden;
  }

  .item {
    z-index: 1;
    pointer-events: none;
    @include singleFixedSize(124px);

    @include min-428-break {
      @include singleFixedSize(170px);
    }

    @include min-744-break {
      @include singleFixedSize(150px);
    }
  }
}

.bottom-slot {
  display: flex;
  position: absolute;
  bottom: 8px;
  right: 8px;

  @include min-428-break {
    bottom: 10px;
    right: 10px;
  }
  @include singleFixedSize(max-content);
}
